import React from 'react';

type ItemProps = {
    imageSrc: string;
    title: string;
    href: string;
    className?: string;
};

const Item = ({ imageSrc, title, href, className }: ItemProps) => {
    const itemCss =
        className === undefined
            ? 'flex flex-col items-center text-center justify-center'
            : `flex flex-col items-center text-center justify-center ${className}`;
    return (
        <div className={itemCss}>
            <img src={imageSrc} alt={title} className='w-40' />
            <h6 className='mt-8 text-[20px]'>{title}</h6>
            <a
                className='text-white text-center py-2 px-8 mt-4 bg-[#2AB4C5] hover:bg-[#5fcfdd]'
                target='_blank'
                href={href}
            >
                I'm interested
            </a>
        </div>
    );
};

export default Item;
