import React from 'react';

type HeadingProps = {
    title: string;
};

const Heading = ({ title }: HeadingProps) => {
    return <h3 className='text-[24px] font-bold text-center mt-20 mb-8'>{title}</h3>;
};

export default Heading;
