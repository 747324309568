import { GetServerDataProps, GetServerDataReturn, HeadFC, navigate } from 'gatsby';
import React, { useEffect } from 'react';
import { Contact } from '../models/contact';

import axios from 'axios';
import Heading from '../components/Heading';
import Item from '../components/Item';
import Img1 from '../images/img1.png';
import Img2 from '../images/img2.png';
import Img3 from '../images/img3.png';
import KinaliLogo1 from '../images/logo-kinali-1.svg';
import KinaliLogo2 from '../images/logo-kinali-2.svg';

interface ServerData {
    contact: Contact;
    campaignId: string;
    redirect: boolean;
}

const IndexPage: React.FC<{ serverData: ServerData }> = ({ serverData }) => {
    useEffect(() => {
        if (serverData.redirect) {
            navigate('/404');
        }
    }, []);

    const attr = `?utm_source=emailing&utm_medium=email&utm_campaign=${serverData.campaignId}&utm_id=${serverData.campaignId}&utm_content=${serverData?.contact?.email}`;
    return serverData.redirect ? null : (
        <div className='flex flex-col items-center justify-center'>
            <div className='max-w-[960px] px-5 md:px-10 lg:px-16'>
                <main>
                    <section className='min-h-screen flex flex-col justify-between'>
                        <img src={KinaliLogo1} alt='Kinali Logo' className='w-16 md:w-24' />

                        <div className='flex flex-col items-center justify-between'>
                            <div className='rounded-full shadow-[0px_0px_14px_0px_rgba(0,0,0,0.2)] px-10 py-4 md:py-8 flex items-center justify-center'>
                                <p className='text-[20px] md:text-[35px] font-bold text-center'>
                                    {serverData.contact.companyName}
                                </p>
                            </div>
                            <p className='text-[35px] font-bold text-center my-1 md:my-10'>and</p>
                            <div className='rounded-full shadow-[0px_0px_14px_0px_rgba(0,0,0,0.2)] px-24 py-3 md:py-5 flex items-center justify-center'>
                                <img
                                    src={KinaliLogo2}
                                    alt='Test-it-off Logo'
                                    className='w-24 md:w-32'
                                />
                            </div>
                        </div>
                        <p className='text-[6px] leading-4 md:text-[16px] md:leading-8'>&nbsp;</p>
                        <h1 className='text-[35px] font-bold text-center'>could get along.</h1>
                        <p className='text-[6px] leading-4 md:text-[16px] md:leading-8'>&nbsp;</p>

                        <div className='flex flex-col items-center'>
                            <p className='text-[20px] leading-8 text-center'>
                                Based on your production programme, we believe that{' '}
                                <span className='font-bold'>
                                    quality control of extruded plastic materials and products
                                </span>{' '}
                                could be an important part of your production process.
                            </p>
                            <p className='text-[6px] leading-4 md:text-[16px] md:leading-8'>
                                &nbsp;
                            </p>
                            <p className='text-[6px] leading-4 md:text-[16px] md:leading-8'>
                                &nbsp;
                            </p>
                            <a
                                className='text-white md:text-[16px] lg:text-[20px] font-bold text-center py-2 md:py-4 lg:py-6 px-14 lg:px-20 bg-[#2AB4C5] hover:bg-[#5fcfdd]'
                                href='#continue'
                            >
                                That's right, tell me more
                            </a>
                        </div>
                        <div className='flex flex-col items-center'>
                            <p className='text-[6px] leading-4 md:text-[16px] md:leading-8'>
                                &nbsp;
                            </p>
                            <span className='font-bold text-base md:text-xl'>- - -</span>
                            <p className='text-[6px] leading-4 md:text-[16px] md:leading-8'>
                                &nbsp;
                            </p>
                        </div>
                    </section>

                    <section id='continue'>
                        <p className='text-[6px] leading-4 md:text-[16px] md:leading-8'>&nbsp;</p>
                        <p className='text-[6px] leading-4 md:text-[16px] md:leading-8'>&nbsp;</p>
                        <p className='text-[16px] leading-8'>
                            Whether it's the{' '}
                            <span className='font-bold'>
                                dimensions, the colour consistency of all manufactured pieces or the
                                assurance that the surface of your products is defect-free
                            </span>
                            , KINALI's AI-equipped optical inspection devices can check all these
                            parameters better and faster than people.
                        </p>
                        <p className='text-[16px] leading-8'>&nbsp;</p>

                        <p className='text-[16px] leading-8'>
                            Human quality control is subject to many factors that increase the
                            instability and subjectivity of results. These include:
                        </p>
                        <ul className='list-disc mt-[1em] mb-[1em] pl-[40px]'>
                            <li>current state of health,</li>
                            <li>the state of your eyesight,</li>
                            <li>different lighting conditions,</li>
                            <li>psychological state of mind,</li>
                            <li>fatigue, etc.</li>
                        </ul>
                        <p className='text-[16px] leading-8'>&nbsp;</p>

                        <p className='text-[16px] leading-8'>
                            Thanks to these factors, we know from experience that a human inspector
                            will often assess the same product differently in two separate cases.
                        </p>
                        <p className='text-[16px] leading-8'>&nbsp;</p>

                        <p className='text-[16px] leading-8'>
                            <span className='font-bold'>
                                Automation of quality control for plastic products
                            </span>{' '}
                            can eliminate all these problems with one solution. Every machine we
                            build for our clients is custom designed. As a result, we achieve
                            significant savings and maximum simplicity in the form of fully
                            automated operation, this includes transmitting results to higher-level
                            MES systems.
                        </p>
                    </section>

                    <section>
                        <Heading title='What we can control' />
                        <div className='flex flex-wrap justify-center sm:justify-between gap-12'>
                            <Item
                                imageSrc={Img1}
                                title='Dimensional control'
                                href={`https://www.kinali.cz/en/optical-quality-control/dimension-control/${attr}`}
                            />
                            <Item
                                imageSrc={Img2}
                                title='Surface control'
                                href={`https://www.kinali.cz/en/optical-quality-control/surface-control/${attr}`}
                            />
                            <Item
                                imageSrc={Img3}
                                title='Colour control'
                                href={`https://www.kinali.cz/en/optical-quality-control/colour-control/${attr}`}
                            />
                        </div>
                        <p className='text-[16px] leading-8'>&nbsp;</p>
                        <p className='text-[16px] leading-8'>
                            We can inspect endless strips of material as well as specific pieces of
                            plastic or finished products. It just depends on your specific
                            requirements.
                        </p>
                    </section>

                    <section>
                        <Heading title='Why you might benefit from optical quality control' />
                        <ul className='list-disc mt-[1em] mb-[1em] pl-[40px]'>
                            <li>
                                It guarantees zero product complaints and therefore a very fast
                                return on your investment in equipment (from as little as one year).
                            </li>
                            <li>
                                You can control 24/7 (including "dark factory") without the typical
                                costs of multi-shift operation (extra charges for weekends and
                                holidays, night work, etc.).
                            </li>
                            <li>
                                Machine inspection is not affected by health, mental well-being or
                                emotions. The inspection results are always objective under all
                                circumstances objective.
                            </li>
                            <li>
                                Thanks to the state-of-the-art optics, even defects, dimensional
                                deviations or colour inconsistencies that are imperceptible to the
                                human eye can be checked.
                            </li>
                            <li>
                                The machine will never leave, become sick or ask for a pay increase.
                                It will not sabotage inspection speed standards and provides you
                                with a level of quality inspection that is often not possible with
                                humans.
                            </li>
                        </ul>
                    </section>

                    <section>
                        <Heading title='Why cooperate with KINALI' />
                        <ul className='list-disc mt-[1em] mb-[1em] pl-[40px]'>
                            <li>
                                Hardware and software under one roof. We have our own engineers and
                                software development team. You only need one entity to collaborate
                                on the final solution.
                            </li>
                            <li>
                                We pride ourselves on minimalist solutions that deliver maximum
                                benefit at the lowest possible cost.
                            </li>
                            <li>
                                Our solutions are always ready for Industry 4.0. They can be
                                connected to other production nodes or controlled by MES/ERP
                                systems.
                            </li>
                            <li>
                                You get online access to data and inspection results wherever you
                                are.
                            </li>
                            <li>
                                We are a stable Czech company with 17 years of tradition. We are
                                always there for you in the event of any future problems.
                            </li>
                        </ul>
                        <p className='text-[16px] leading-8'>&nbsp;</p>
                    </section>
                    <section>
                        <p className='text-[16px] leading-8'>
                            Still hesitating?{' '}
                            <span className='font-bold'>
                                Take a look at the specific cases of our clients and how we solved
                                them.
                            </span>{' '}
                            Or contact us without obligation. We will discuss your requirements and
                            our options to help you, over a delicious coffee.
                        </p>
                    </section>
                    <section className='flex flex-col md:flex-row md:items-stretch items-center content-center justify-center text-center font-bold gap-10 mt-12 mb-24'>
                        <a
                            target='_blank'
                            className='bg-[#2AB4C5] text-white py-5 px-2 md:w-5/12 w-11/12 hover:bg-[#5fcfdd]'
                            href={`https://www.kinali.cz/en/optical-quality-control/${attr}`}
                        >
                            Further information and solution examples
                        </a>
                        <a
                            target='_blank'
                            className='flex items-center justify-center bg-[#E8E6E6] text-[#2AB4C5] py-5 px-2 md:w-5/12 w-11/12 hover:bg-[#f4f3f3]'
                            href={`https://www.kinali.cz/en/contact/${attr}`}
                        >
                            Contact for a non-binding meeting
                        </a>
                    </section>
                </main>

                <footer className='border-t-2 border-[#2AB4C5]'>
                    <p className='text-center text-[14px] leading-5 mt-1 mb-10'>
                        <a
                            target='_blank'
                            href={`https://www.kinali.cz/en/${attr}`}
                            className='underline'
                        >
                            KINALI
                        </a>{' '}
                        | Cejl 37/62, 602 00 Brno | e-mail:{' '}
                        <a href='mailto:info@kinali.cz' className='underline'>
                            info@kinali.cz
                        </a>{' '}
                        | tel.:{' '}
                        <a href='tel:+420511112218' className='underline'>
                            +420 511112218
                        </a>
                    </p>
                </footer>
            </div>
        </div>
    );
};

export default IndexPage;

export const Head: HeadFC = () => <title>KINALI</title>;

export async function getServerData(data: GetServerDataProps): GetServerDataReturn {
    // const mockContact = {
    //     companyName: 'IZOL-PLASTIK, s.r.o.',
    //     email: 'info@izolplastik.cz',
    // } as Contact;
    // return {
    //     status: 200,
    //     props: {
    //         contact: mockContact,
    //         campaignId: 'plasty-de-1'
    //         redirect: false,
    //     },
    // };
    if (data.query && data.query.c !== undefined) {
        const contactId = data?.query['c'] as string;
        const campaignId = (data?.query['utm_campaign'] as string) ?? 'plasty-de-1';
        try {
            const endpoint: string = `http://kinalisoft-marketing-plastics-eng-webapi:5309/companies/${contactId}`;
            const response = await axios.get<Contact>(endpoint);
            return {
                status: 200,
                props: {
                    contact: response.data,
                    campaignId: campaignId,
                    redirect: false,
                },
            };
        } catch (error) {}
    }
    return {
        status: 200,
        props: {
            contact: undefined,
            campaignId: undefined,
            redirect: true,
        },
    };
}
